<template>
  <div >
    <el-row :gutter="10">

      <el-col class="library-view-number-multiple-col" :span="8">
        <div><b>A</b>: {{ scale.target_a }}</div>
      </el-col>
      <el-col class="library-view-number-multiple-col" :span="8">
        <div><b>B</b>: {{ scale.target_b }}</div>
      </el-col>
      <el-col class="library-view-number-multiple-col" :span="8">
        <div><b>C</b>: {{ scale.target_c }}</div>
      </el-col>

    </el-row>
  </div>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import {variables} from "../../variables";

export default {
  name: "library-view",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {},

  data() {
    return {
      scale: {
        target_a: null,
        target_b: null,
        target_c: null,
        pay_a: null,
        pay_b: null,
        pay_c: null,
      },
    }
  },
  mounted() {

  },
  computed: {
    variables() {
      return variables
    }
  },
  methods: {}
}
</script>

<style>
</style>